<template>
    <div>
<v-data-table
    :headers="headers"
    :items="order.items"
    :items-per-page="5"
    class="elevation-1"
></v-data-table>
    </div>
</template>

<script>
import { errorHandler, fetchGET } from '../js/functions'
    export default {
        data() {
            return {
                order: {
                    items: []
                },
                headers: [
                    { text: 'Product', value: 'name' },
                    { text: 'Aantal', value: 'amount' },
                    { text: 'Eenheid', value: 'unit' },
                    { text: 'Lotnr', value: 'lotNumber' },
                    { text: 'Geboren in', value: 'birthCountry' },
                    { text: 'Gemest/gehouden in', value: 'residenceCountry' },
                    { text: 'Geslacht in', value: 'slaughterCountry' },
                ]
            }
        },
        methods: {
            async fetchOrder(_id) {
                let data = {
                    _id: _id
                }
                try {
                    const response = await fetchGET('fetchOrderByQR', data);
                    if (!response || response.length === 0) throw 'Geen order gevonden';
                    this.order = response[0];
                } catch (error) {
                    errorHandler(error);
                }
            },
            },
        mounted() {
            this.fetchOrder(this.$route.params.id)
        }
        }
</script>

<style lang="scss" scoped>

</style>